import React from 'react';
import Container from "@mui/material/Container";
import {Box, Grid} from "@mui/material";
import {Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import consultantImage from '../Resources/consultant.jpg';


function AboutUs() {
  return (
      <div className={"AboutUs-Container"}>
          <Container maxWidth="lg">
              <Grid container spacing={2}>
                  <Grid item
                        xs={12} sm={8}
                        justifyContent="flex-end"
                        alignItems="center">
                  <div>
                      <div className={"Header-Text-Black"}>
                          About Us
                      </div>
                      <div className={"Header-Text2"}>
                          Tektify – A Division of Tellogic Inc.
                      </div>
                      <div className={"Company-Description-Text"}>
                          At Tektify, we specialize in bridging the gap between technology and business needs,
                          with a special focus on healthcare IT solutions and bespoke software development.
                          As a division of Tellogic Inc., we bring a unique blend of technical expertise and industry
                          insight to deliver tailored technology solutions.
                      </div>
                  </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                      <div className={"Paper-Container"}>
                      <Paper elevation={3} className={"Paper-Container"}>
                      <Box
                          component="img"
                          sx={{
                              width: '100%',
                              height: 'auto',
                              maxWidth: { xs: '100%', sm: 350, md: 500, lg: 700 },
                              maxHeight: { xs: 'auto', sm: 233, md: 350, lg: 500 },
                          }}
                          alt="The house from the offer."
                          src={consultantImage}
                      />
                      </Paper>
                      </div>
                  </Grid>
              </Grid>
          </Container>
      </div>
  );
}

export default AboutUs;