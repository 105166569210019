import React from "react";
import {Accordion, AccordionDetails, AccordionSummary, Container, createTheme, Grid, ThemeProvider} from "@mui/material";
import Paper from "@mui/material/Paper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";



function ServicesGrid() {
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };
    const theme = createTheme({
        typography: {
            subtitle1: {
                fontSize: 12,
            },
            h6: {
                fontWeight: 500,
            },
            button: {
                fontStyle: 'italic',
            },
        },
    });
    return (
        <ThemeProvider theme={theme}>
        <div className={"Paper-Container"}>
        <Grid container className={"Services-Container"} spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
                <Paper elevation={3} square={false} className={"Paper-Container"}>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography variant={"h6"} sx={{ width: '100%', flexShrink: 0 }}>
                                Healthcare Information Technology Solutions
                            </Typography>

                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Our team of healthcare IT experts can help you navigate the complex world of healthcare
                                technology. We can help you with everything from EHR implementation to custom software
                                development.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Paper elevation={3} square={false} className={"Paper-Container"}>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography variant={"h6"} sx={{ width: '100%', flexShrink: 0 }} className={"Services-Header"}>
                                Custom Software Development
                            </Typography>

                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Our team offers Custom Software Development services tailored to meet our clients'
                                unique needs. We pride ourselves on creating bespoke software solutions that precisely
                                align with your business requirements. Additionally, we excel in mobile app development
                                across various industries, including healthcare.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Paper elevation={3} square={false} className={"Paper-Container"}>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography variant={"h6"} sx={{ width: '100%', flexShrink: 0 }}>
                                Cybersecurity and Data Protection
                            </Typography>

                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Our team is dedicated to cybersecurity and data protection. We provide comprehensive
                                assessments and solutions to mitigate cybersecurity risks, with a particular focus
                                on the healthcare sector. Ensuring compliance with data protection laws, such as HIPAA,
                                is a top priority for our experts.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Paper elevation={3} square={false} className={"Paper-Container"}>
                    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography variant={"h6"} sx={{ width: '100%', flexShrink: 0 }} className={"Services-Header"}>
                                IT Infrastructure and Cloud Services
                            </Typography>

                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Our team specializes in IT Infrastructure and Cloud Services, including setting up
                                and managing robust IT systems. We guide you through cloud migration and offer
                                cloud-based solutions to ensure efficient, scalable IT infrastructure for your
                                organization. We are committed to optimizing your technology environment.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Paper elevation={3} square={false} className={"Paper-Container"}>
                    <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography variant={"h6"} sx={{ width: '100%', flexShrink: 0 }} className={"Services-Header"}>
                                Technology Integration and Automation
                            </Typography>

                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Our team excels in Technology Integration and Automation, seamlessly integrating
                                cutting-edge technologies into your existing business processes. We also implement
                                automation tools to optimize operational efficiency, driving growth and productivity
                                across your organization.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Paper elevation={3} square={false} className={"Paper-Container"}>
                    <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography variant={"h6"} sx={{ width: '100%', flexShrink: 0 }} className={"Services-Header"}>
                                Digital Transformation Consulting
                            </Typography>

                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Our team provides Digital Transformation Consulting to guide your business
                                through transformative initiatives. We help you navigate the digital landscape,
                                adopting new technologies to enhance growth and operational efficiency. Our
                                expertise empowers your organization to thrive in the digital age.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Paper elevation={3} square={false} className={"Paper-Container"}>
                    <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography variant={"h6"} sx={{ width: '100%', flexShrink: 0 }} className={"Services-Header"}>
                                Software Training and Support
                            </Typography>

                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Our team offers comprehensive training programs for your staff to ensure
                                a smooth transition to new technologies. We are committed to providing
                                ongoing technical support and maintenance services, keeping your systems
                                running seamlessly and your team well-equipped to leverage the latest tools
                                and solutions.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Paper elevation={3} square={false} className={"Paper-Container"}>
                    <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography variant={"h6"} sx={{ width: '100%', flexShrink: 0 }} className={"Services-Header"}>
                                User Experience (UX) Design Optimization
                            </Typography>

                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Our team specializes in User Experience (UX) Design Optimization services,
                                ensuring that your software solutions not only function seamlessly but also
                                provide a user-friendly and engaging experience. We conduct in-depth user research,
                                usability testing, and interface design enhancements to maximize user satisfaction
                                and increase the overall success of your software products.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Paper elevation={3} square={false} className={"Paper-Container"}>
                    <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography variant={"h6"} sx={{ width: '100%', flexShrink: 0 }} className={"Services-Header"}>
                                Quality Assurance and Testing Services
                            </Typography>

                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Our Quality Assurance and Testing services are designed to ensure the reliability,
                                security, and performance of your software applications. Our experienced testers
                                rigorously assess your software through various testing methodologies, including
                                functional, regression, security, and performance testing, guaranteeing a bug-free
                                and high-quality end product. We help you maintain the trust of your users and the
                                integrity of your software.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Paper>
            </Grid>
        </Grid>
        </div>
        </ThemeProvider>
    );
}

export default ServicesGrid;