import React from 'react';
import './App.css';
import Container from '@mui/material/Container';
import { motion } from "framer-motion";
import ParallaxText from "./Components/Scroller";
import ServicesGrid from "./Components/ServicesGrid";
import AboutUs from "./Components/AboutUs";



function App() {

  return (
    <div className="App">
      <header className="App-header">
          <Container maxWidth="lg">
          <div className={"Company-Name"}>
              <motion.div
                whileHover={{color: "#FF0000"}}
                transition={{ duration: 0.2 }}
                >
            Tektify
                  </motion.div>
          </div>
          <div className={"Company-Subtitle"}>
              Revolutionizing the way you work with ai
            </div>
          </Container>
      </header>
        <AboutUs></AboutUs>
        <div className={"Container-White"}>
            <Container maxWidth={"lg"}>
                <div className={"Header-Text2"}>
                    How we can help
                </div>
                <div className={"Company-Description-Text"}>
                    Our diverse team of tech strategists, enterprise architects, and transformation experts collaborates to leverage technology and innovation for the purpose of fundamentally reshaping and revitalizing businesses.
                </div>
                <ServicesGrid></ServicesGrid>
            </Container>
        </div>
        <ParallaxText></ParallaxText>
    </div>

  );
}

export default App;
